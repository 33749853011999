import { useState, useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

import './App.css';

function App() {
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [, setErro] = useState(null);

  const handleSaveId = () => {
    // Abre o prompt e solicita o ID
    const userId = window.prompt("Digite o seu ID:");

    // Verifica se um ID foi fornecido
    if (userId) {
      localStorage.setItem("userId", userId); // Salva no localStorage
      alert("ID salvo com sucesso!"); // Notifica o usuário
    } else {
      alert("Nenhum ID foi fornecido."); // Caso o usuário cancele ou não insira nada
    }
  };

  useEffect(() => {
    // Busca o ID armazenado no localStorage
    const storedId = localStorage.getItem("userId");
    if (storedId) {
      setUserId(storedId); // Atualiza o estado com o valor encontrado
    }else{
      handleSaveId()
    }
  }, []);

  useEffect(() => {
    // Busca o ID armazenado no localStorage
    const storedId = localStorage.getItem("userId");
    if (storedId) {
      setUserId(storedId); // Atualiza o estado com o valor encontrado
    }else{
      handleSaveId()
    }
  }, [data]);



  const getRun = () => {
    fetch('https://ubergoianinha.com.br:8080/v1/races')
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro na requisição');
        }
        return response.json();
      })
      .then(data => {
        setData(data);
      })
      .catch(error => {
        setErro(error.message);
    });
  };

  useEffect(() => {
     getRun();

     const intervalId = setInterval(getRun, 5000);
 
     return () => clearInterval(intervalId);
  }, []);

  const handleFinishRun = (id, business_phone_number_id, number_phone) => {
    fetch('https://ubergoianinha.com.br:8080/v1/races/finish', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          id: `${id}`,
          accept: true,
          business_phone_number_id: `${business_phone_number_id}`,
          number_phone: `${number_phone}`
        }
      )
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro na requisição');
      }
      return response.json();
    })
    .then(data => {
      getRun();
    })
    .catch(error => {
      setErro(error.message);
    });
  }

  const handleCancelAfterClient = (id, business_phone_number_id, number_phone) => {
    fetch('https://ubergoianinha.com.br:8080/v1/races/confirmcancellation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          id: `${id}`,
          accept: true,
          business_phone_number_id: `${business_phone_number_id}`,
          number_phone: `${number_phone}`
        }
      )
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro na requisição');
      }
      return response.json();
    })
    .then(data => {
      getRun();
    })
    .catch(error => {
      setErro(error.message);
    });
  }

  const handleCancelRun = (id, business_phone_number_id, number_phone) => {
    fetch('https://ubergoianinha.com.br:8080/v1/races/cancel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          id: `${id}`,
          accept: true,
          business_phone_number_id: `${business_phone_number_id}`,
          number_phone: `${number_phone}`
        }
      )
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro na requisição');
      }
      return response.json();
    })
    .then(data => {
      getRun();
    })
    .catch(error => {
      setErro(error.message);
    });
  }

  const handleConfirmRun = (id, business_phone_number_id, number_phone) => {
    fetch('https://ubergoianinha.com.br:8080/v1/races/confirm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          id: `${id}`,
          accept: true,
          business_phone_number_id: `${business_phone_number_id}`,
          number_phone: `${number_phone}`,
          id_driver: `${userId}`
        }
      )
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro na requisição');
      }
      return response.json();
    })
    .then(data => {
      getRun();
    })
    .catch(error => {
      setErro(error.message);
    });
  }
  return (
    <div className="container">
      <header className="container__header">
        <h2 className="container__header--logo">Uber Goianinha</h2>
      </header>
      <div className="container--list">
        {data?.map((races) => {
          if(!races.is_race_confirmed_by_client || races.is_canceled_by_client || races?.status === "theRaceWasCanceled" || races?.is_canceled_by_driver || races.is_finished_by_driver){
            if(!races.is_race_confirmed_by_driver || races.is_canceled_by_driver || races.is_finished_by_driver || races?.status === "theRaceWasCanceled" )
              return <></>
          }
          
          if(races.is_race_confirmed_by_client && races.is_race_confirmed_by_driver && races.is_canceled_by_client){
            return (
              <div key={races?.id} className="container--list--card">
                <div>
                  <p><strong>{races?.client?.name}</strong> - {races?.client?.client?.number_phone_client}</p>
                  <p>Local: {races?.address?.street}, {races?.address?.number} - {races?.address?.neighborhood} - {races?.address?.city} / RN</p>
                  <p><strong>Destino:</strong> {races?.address?.neighborhood_destination}</p>
                  <div className="container--list__button">
                    <button 
                      onClick={() => handleCancelAfterClient(races?.id, races?.client?.business_phone_number_id, races?.client?.number_phone_client)} 
                      className="container--list__buttondeny">
                      CORRIDA CANCELADA PELO CLIENTE!
                    </button>
                  </div>
                </div>
                <a target="__blank" href={`https://api.whatsapp.com/send/?phone=${races?.client?.number_phone_client}&text&type=phone_number&app_absent=0`} className="container--button__whatsapp">
                  <FaWhatsapp color="#FFFFFF" size={32} />
                </a>
              </div>
            )
          }

          return(
            <div key={races?.id} className="container--list--card">
              { races?.address?.is_location_of_client &&
                <a target="__blank" href={`https://maps.google.com/?q=${races?.address?.latitude},${races?.address?.longitude}`} className="container--button__location">
                  <FaLocationDot color="#FFFFFF" size={32} />
                </a>
              }
              <div>
                {races?.is_race_confirmed_by_driver &&
                  <div className="container--list__button">
                    <button onClick={() => handleFinishRun(races?.id, races?.client?.business_phone_number_id, races?.client?.number_phone_client)} className="container--list__buttonconfirm">
                      CONCLUIR CORRIDA 
                    </button>
                  </div>
                }
                <p><strong>{races?.client?.name}</strong> - {races?.client?.number_phone_client}</p>
                <p><strong>Corrida aceita por: </strong> - {races?.driver?.name}</p>
                <p>Local: {races?.address?.street}, {races?.address?.number} - {races?.address?.neighborhood} - {races?.address?.city} / RN</p>
                <p><strong>Destino:</strong> {races?.address?.neighborhood_destination}</p>
                {races?.is_race_confirmed_by_driver ?
                  <div className="container--list__button">
                    <button 
                      onClick={() => handleCancelRun(races?.id, races?.client?.business_phone_number_id, races?.client.number_phone_client)}  
                      className="container--list__buttondeny"
                    >
                      CANCELAR CORRIDA
                    </button>
                  </div>
                :
                  <button className="container--list__buttonconfirm" onClick={() => handleConfirmRun(races?.id, races?.client?.business_phone_number_id, races?.client?.number_phone_client, 1)}>
                    ACEITAR CORRIDA
                  </button>
                }
              </div>
              <a target="__blank" href={`https://api.whatsapp.com/send/?phone=${races?.client?.number_phone_client}&text&type=phone_number&app_absent=0`} className="container--button__whatsapp">
                <FaWhatsapp color="#FFFFFF" size={32} />
              </a>
            </div>
        )})}
      </div>
    </div>
  );
}

export default App;
